<template>
  <div class="calendar">
    <div class="header">
      <button
        @click="previousMonth"
        :disabled="disabled"
        class="nav button left"
      >
        <i class="fas fa-chevron-left"></i>
      </button>

      <div class="nav title">{{ viewString }}</div>

      <button @click="nextMonth" :disabled="disabled" class="nav button right">
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
    <div class="body">
      <div class="week-day" :key="weekDay" v-for="weekDay in weekDays">
        {{ weekDay }}
      </div>
      <button
        class="day"
        :class="{
          selected: item.isView && item.date.getTime() === selectedTimestamp,
        }"
        :disabled="!item.isView || !isInMinMax(item.date) || disabled"
        :key="item.year + '-' + item.month + '-' + item.day"
        v-for="item in days"
        @click="selectDate(item.date)"
      >
        {{ item.day }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Date,
    initDate: Date,
    minDate: Date,
    maxDate: Date,
    disabled: Boolean,
  },
  data() {
    let date = this.initDate;
    const normalizedMinDate = this.normalizeDate(this.minDate);
    const normalizedMaxDate = this.normalizeDate(this.maxDate);

    if (!this.initDate) {
      const today = this.normalizeDate(new Date());
      const minDateTime = normalizedMinDate?.getTime() || -Infinity;
      const maxDateTime = normalizedMaxDate?.getTime() || Infinity;

      if (minDateTime <= today.getTime() && today.getTime() <= maxDateTime) {
        date = today;
      } else {
        date = normalizedMinDate;
      }
    }

    return {
      lang: "es",
      view: date,
      normalizedMinDate: normalizedMinDate,
      normalizedMaxDate: normalizedMaxDate,
    };
  },
  computed: {
    viewString() {
      return this.view.toLocaleDateString(this.lang, {
        month: "long",
        year: "numeric",
      });
    },

    viewYear() {
      return this.view.getFullYear();
    },
    viewMonth() {
      return this.view.getMonth();
    },

    selectedTimestamp() {
      return this.value?.getTime();
    },
    selectedYear() {
      return this.value?.getFullYear();
    },
    selectedMonth() {
      return this.value?.getMonth();
    },
    selectedDay() {
      return this.value?.getDate();
    },

    weekDays() {
      if (this.lang === "es") return ["L", "M", "X", "J", "V", "S", "D"];
      if (this.lang === "ca") return ["Dl", "Dt", "Dc", "Dj", "Dv", "Ds", "Dg"];
      return ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
    },

    days() {
      const days = [];

      const year = this.viewYear;
      const month = this.viewMonth;

      const preDaysNeeded = [6, 0, 1, 2, 3, 4, 5];

      const previousMonthDaysNeeded =
        preDaysNeeded[new Date(year, month, 1).getDay()];

      for (let i = previousMonthDaysNeeded; i > 0; i--) {
        const date_temp = new Date(year, month, 1 - i);

        days.push({
          day: date_temp.getDate(),
          month: date_temp.getMonth() + 1,
          year: date_temp.getFullYear(),
          date: date_temp,
          isView: false,
        });
      }

      const lastDayMonth = new Date(year, month + 1, 0).getDate();

      for (let day = 1; day <= lastDayMonth; day++) {
        days.push({
          day: day,
          month: month + 1,
          year: year,
          date: new Date(year, month, day),
          isView: true,
        });
      }

      const nextDaysNeeded = [0, 6, 5, 4, 3, 2, 1];

      const nextMonthDaysNeeded =
        nextDaysNeeded[new Date(year, month + 1, 0).getDay()];

      for (let i = 0; i < nextMonthDaysNeeded; i++) {
        const date_temp = new Date(year, month + 1, i + 1);

        days.push({
          day: date_temp.getDate(),
          month: date_temp.getMonth() + 1,
          year: date_temp.getFullYear(),
          date: date_temp,
          isView: false,
        });
      }

      return days;
    },
  },
  methods: {
    selectDate(date) {
      this.$emit("input", date);
    },

    previousMonth() {
      const newViewDate = new Date(this.viewYear, this.viewMonth, 0);

      if (this.normalizedMinDate)
        if (!(this.normalizedMinDate.getTime() <= newViewDate.getTime())) {
          return;
        }

      this.view = newViewDate;
    },

    nextMonth() {
      const newViewDate = new Date(this.viewYear, this.viewMonth + 1, 1);

      if (this.normalizedMaxDate)
        if (!(newViewDate.getTime() <= this.normalizedMaxDate.getTime())) {
          return;
        }

      this.view = newViewDate;
    },

    isInMinMax(date) {
      if (this.normalizedMinDate) {
        if (this.normalizedMinDate.getTime() > date.getTime()) {
          return false;
        }
      }

      if (this.normalizedMaxDate) {
        if (this.normalizedMaxDate.getTime() < date.getTime()) {
          return false;
        }
      }

      return true;
    },

    normalizeDate(date) {
      if (date instanceof Date)
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
$color-text: #6c757d;
$color-focus: #1f5eff;
$color-text-hover: #fff;
$color-text-selected: #fff;
$color-bg: #eef2f7;
$color-bg-hover: #8b9acc;

.calendar {
  flex: 0 0 auto;
  // margin: 0 auto;
  // outline: 1px solid red;
  // background-color: #fff;

  min-height: 200px;

  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 0 2px 1px #ccc;

  width: 100%;
  max-width: 300px;

  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.nav.title {
  color: #444b50;
}

.nav.button {
  border: none;
  outline: none;
  background-color: unset;
  cursor: pointer;

  &:focus-visible {
    box-shadow: 0 0 0 2px #552cf6;
  }
}

.body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 4px;
}

.week-day {
  display: flex;
  justify-content: center;
  align-items: center;
}

.body .day {
  height: 32px;
  outline: none;
  border: none;
  border-radius: 5px;
  // border: 2px solid #1f5ceb;
  color: $color-text;
  background-color: $color-bg;

  cursor: pointer;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    // color: #5931f6;
    color: $color-text-hover;
    background-color: $color-bg-hover;

    &:focus-visible {
      box-shadow: 0 0 0 2px $color-focus;
    }
  }

  &:disabled {
    cursor: default;
    background-color: unset;
  }

  &.selected {
    color: $color-text-selected;
    background-color: #536de6;
  }
}
</style>
