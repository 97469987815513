<template>
  <router-view />
</template>

<script>
export default {
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.$store.dispatch("routeChanged");
      },
    },
  },
};
</script>
