<template>
  <div class="container">
    <button
      :class="{ selected: isSelected(item) }"
      :key="JSON.stringify(item)"
      v-for="item in items"
      @click="select(item)"
    >
      {{ item[0] }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    items: Array,
  },
  methods: {
    select(item) {
      this.$emit("input", item);
    },

    isSelected(item) {
      if (JSON.stringify(this.value) == JSON.stringify(item)) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
$color-text: #6c757d;
$color-focus: #1f5eff;
$color-text-hover: #fff;
$color-text-selected: #fff;
$color-bg: #eef2f7;
$color-bg-hover: #8b9acc;

.container {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;

  overflow: auto;
}

button {
  flex: 0 0 24%;
  min-width: 80px;

  height: 35px;
  outline: none;
  border: none;
  border-radius: 5px;
  // border: 2px solid #1f5ceb;
  color: $color-text;
  background-color: $color-bg;

  cursor: pointer;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    // color: #5931f6;
    color: $color-text-hover;
    background-color: $color-bg-hover;

    &:focus-visible {
      box-shadow: 0 0 0 2px $color-focus;
    }
  }

  &:disabled {
    cursor: default;
    background-color: unset;
  }

  &.selected {
    color: $color-text-selected;
    background-color: #536de6;
  }
}
</style>
