import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: "es",
    user: null,
    token: localStorage.getItem("token"),
    client: null,
    calendar: null,
  },
  mutations: {
    user: function (state, payload) {
      state.user = payload;
    },
    token: function (state, payload) {
      state.token = payload;

      payload
        ? localStorage.setItem("token", payload)
        : localStorage.removeItem("token");
    },
    client: function (state, payload) {
      state.client = payload;
    },
    calendar: function (state, payload) {
      state.calendar = payload;
    },
  },
  actions: {
    routeChanged: ({ dispatch }) => {
      dispatch("bindTokenAxios");
      dispatch("checkToken");
      dispatch("checkSlugs");
    },

    checkToken: async ({ state, commit }) => {
      if (state.token && state.user === null) {
        await axios
          .get("/auth")
          .then((response) => {
            commit("user", response.data ?? null);
          })
          .catch(() => {
            commit("user", null);
            commit("token", null);
          });
      }
    },

    bindTokenAxios: ({ state }) => {
      if (state.token) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
      } else {
        delete axios.defaults.headers.common["Authorization"];
      }
    },

    checkSlugs: async ({ state, dispatch }) => {
      const { client_slug, calendar_slug } = router.currentRoute.params;

      if (client_slug && client_slug !== state.client?.slug) {
        await dispatch("fetchClient");
      }

      if (calendar_slug && calendar_slug !== state.calendar?.slug) {
        await dispatch("fetchCalendar");
      }
    },

    login: ({ commit, dispatch }, form) => {
      return new Promise((resolve, reject) => {
        axios
          .post("/auth", {
            email: form.email,
            password: form.password,
            token_name: "frontend",
          })
          .then((response) => {
            commit("user", response.data?.user ?? null);
            commit("token", response.data?.token ?? null);
            dispatch("bindTokenAxios");
            resolve("");
          })
          .catch(() => {
            commit("user", null);
            commit("token", null);
            reject("");
          });
      });
    },

    logout: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        axios
          .delete("/auth")
          .then(() => {
            commit("user", null);
            commit("token", null);
            dispatch("bindTokenAxios");
            resolve("");
          })
          .catch(() => {
            reject("");
          });
      });
    },

    fetchClient: async ({ commit }) => {
      const { client_slug } = router.currentRoute.params;

      await axios
        .get("/clients/" + client_slug)
        .then((response) => {
          commit("client", response.data);
        })
        .catch(() => {
          router.push({
            name: "home",
          });
        });
    },

    fetchCalendar: async ({ state, commit }) => {
      const { calendar_slug } = router.currentRoute.params;

      await axios
        .get("/calendars/" + calendar_slug, {
          params: {
            client_id: state.client.id,
          },
        })
        .then((response) => {
          commit("calendar", response.data);
        })
        .catch(() => {
          router.push({
            name: "public-client-home",
            params: { client_slug: state.client.slug },
          });
        });
    },
  },
  getters: {
    isLoggedIn: function (state) {
      return state.token !== null;
    },
  },
  modules: {},
});
