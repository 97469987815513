import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import axios from "axios";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./main.scss";

axios.defaults.baseURL = "http://217.76.139.155/api";

Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  metaInfo: {
    title: "Reservas",
    htmlAttrs: {
      lang: "es",
    },
  },
}).$mount("#app");
