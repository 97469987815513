import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import CitaInfo from "../views/CitaInfo.vue";
import ClientHome from "../views/ClientHome.vue";
import ClientCalendar from "../views/ClientCalendar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/cites/:appointment_reference",
    name: "public-cita-info",
    component: CitaInfo,
  },
  {
    path: "/:client_slug",
    name: "public-client-home",
    component: ClientHome,
  },
  {
    path: "/:client_slug/:calendar_slug",
    name: "public-client-calendar",
    component: ClientCalendar,
  },
  {
    path: "/:client_slug/cites/:appointment_reference",
    name: "public-cita-info-legacy",
    redirect: {
      name: "public-cita-info",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
