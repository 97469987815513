<template>
  <div class="cs-page">
    <div class="cs-container">
      <div class="cs-header">
        <div class="cs-top">
          <h1 class="cs-title" v-if="client">{{ client.name }}</h1>
          <h1 class="cs-title" v-else>...</h1>
        </div>
        <div class="cs-bottom">
          <h6 class="cs-title">Calendarios</h6>
        </div>
      </div>

      <div class="cs-body">
        <select
          class="form-select form-select-lg mb-3"
          aria-label=".form-select-lg"
          v-model="calendar_slug"
        >
          <option value="" selected disabled>Seleccione el calendario</option>
          <option
            :value="calendar.slug"
            v-for="calendar in calendars"
            :key="calendar.id"
          >
            {{ calendar.name }}
          </option>
        </select>
      </div>

      <div class="cs-footer">
        <button @click="next">Siguiente</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ClientHome",
  metaInfo() {
    return {
      title: this.client?.name,
    };
  },
  data() {
    return {
      calendars: [],
      calendar_slug: "",
    };
  },
  computed: {
    client() {
      return this.$store.state.client;
    },
  },
  watch: {
    client: {
      immediate: true,
      handler: function (value) {
        if (value !== null) {
          this.fetchCalendars();
        }
      },
    },
  },
  methods: {
    async fetchCalendars() {
      const response = await axios.get("/calendars", {
        params: {
          client_id: this.client.id,
        },
      });

      this.calendars = response.data;
    },

    next() {
      this.$router.push({
        name: "public-client-calendar",
        params: {
          client_slug: this.client.slug,
          calendar_slug: this.calendar_slug,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$wizard-padding: 35px;
$items-border-radius: 9px;

.cs-page {
  min-height: 100vh;
  background-color: #f9fafd;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cs-container {
  width: 100%;
  max-width: 900px;

  // height: 500px;
  // outline: 1px solid red;

  background-color: #fefeff;
  border-radius: $items-border-radius;
  box-shadow: 1px 1px 2px 1px #ccc;

  color: #6c757d;

  display: flex;
  flex-direction: column;
}

.cs-header .cs-top {
  height: 80px;

  padding: 0 $wizard-padding;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & .cs-title {
    font-size: inherit;
    font-weight: bold;
  }
}

.cs-header .cs-bottom {
  display: flex;

  padding: 0 $wizard-padding;

  & .cs-step {
    flex: 1 1 0px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    font-weight: 600;

    user-select: none;

    transition: color, background-color 0.3s;
    background-color: #eef2f7;

    &.active {
      color: #fff;
      background-color: #536de6;
    }
  }
}

.cs-body {
  flex: 1 1 0px;
  padding: $wizard-padding;

  display: flex;

  & .cs-step {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  & .cs-step-2 {
    gap: 25px;
    margin: 0 auto;
    max-width: 90%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    .cs-calendar {
      flex: 0 0 auto;
    }

    & .cs-timecards,
    & .cs-message-container {
      flex: 1 1 0px;
      min-width: 320px;
      max-height: 400px;
    }

    & .cs-message-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.cs-body .cs-step .cs-input-group {
  width: 100%;
}

.cs-body .cs-step .cs-input-group {
  & input {
    width: 100%;
    max-width: 300px;
    height: 40px;
    padding: 0 15px;
    border-radius: $items-border-radius;

    outline: none;
    color: #2b303d;
    border: 2px solid #b3bdd9;
    box-shadow: 0 1px 2px #dbdde2;

    transition: box-shadow 0.2s;

    &:focus {
      border-color: #1f5eff;
      box-shadow: 0 0 0 3px #b5c9fc;
    }
  }
}

.cs-footer {
  padding: $wizard-padding;
  padding-top: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    width: 200px;
    height: 40px;

    outline: none;
    border: none;
    border-radius: $items-border-radius;
    color: #fff;
    background-color: #1f5eff;
    border: 2px solid #1f5eff;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    user-select: none;

    &:focus {
      box-shadow: 0 0 0 3px #b5c9fc;
    }
  }
}
</style>
