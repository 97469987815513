<template>
  <div class="cs-page">Falta la información de la escuela en la URL.</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cs-page {
  min-height: 100vh;
  background-color: #f9fafd;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
