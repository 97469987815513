<template>
  <div class="cs-page">
    <div class="cs-container">
      <div class="cs-header">
        <div class="cs-top">
          <h1 class="cs-title">
            <span v-if="client">{{ client.name }}</span>
            <span v-else>...</span>
            <span v-if="calendar"> - {{ calendar.name }}</span>
          </h1>
        </div>
        <div class="cs-bottom">
          <div class="cs-step" :class="{ active: step === 1 }">
            <i class="far fa-user-circle"></i>
            Datos
          </div>
          <div class="cs-step" :class="{ active: step === 2 }">
            <i class="far fa-calendar-alt"></i>
            Día y hora
          </div>
          <div class="cs-step" :class="{ active: step === 3 }">
            <i class="far fa-check-circle"></i>
            Confirmación
          </div>
        </div>
      </div>

      <div class="cs-body">
        <div class="cs-step" v-if="step === 1">
          <div class="cs-input-group" v-if="etapaItems.length > 0">
            <div>Etiqueta</div>
            <select v-model="form.etapa_id">
              <option value="" disabled>Escoge...</option>
              <option
                :value="etapa.id"
                :key="etapa.id"
                v-for="etapa in etapaItems"
              >
                {{ etapa.name }}
              </option>
            </select>
          </div>
          <div class="cs-input-group">
            <div>Nombre</div>
            <input type="text" v-model="form.name" @keypress.enter="next" />
          </div>
          <div class="cs-input-group">
            <div>Email</div>
            <input type="text" v-model="form.email" @keypress.enter="next" />
          </div>
          <div class="cs-input-group">
            <div>Número de teléfono</div>
            <input type="text" v-model="form.phone" @keypress.enter="next" />
          </div>
        </div>

        <div class="cs-step cs-step-2" v-if="step === 2">
          <Calendar
            class="cs-calendar"
            :minDate="new Date()"
            v-model="form.date"
          />
          <TimeCards
            class="cs-timecards"
            :items="timeItems"
            v-model="form.time"
            v-if="timeItems.length > 0"
          />
          <div class="cs-message-container" v-else>
            <div class="cs-message" v-if="!form.date">Seleccione el día.</div>
            <div class="cs-message" v-else-if="form.date && loadingTimes">
              Cargando citas...
            </div>
            <div
              class="cs-message"
              v-else-if="form.date && timeItems.length < 1"
            >
              No hay citas este día.
            </div>
          </div>
        </div>

        <div class="cs-step" v-if="step === 3">
          <b>Confirmación</b>
          <div>
            <small><b>Nombre:</b></small> {{ form.name }}
          </div>
          <div>
            <small><b>Email:</b></small> {{ form.email }}
          </div>
          <div>
            <small><b>Número de teléfono:</b></small> {{ form.phone }}
          </div>
          <div>
            <small><b>Día:</b></small> {{ form.date | formatDate }}
          </div>
          <div>
            <small><b>Hora:</b></small> {{ formTime }}
          </div>
          <br />
          <div v-if="codi">
            <small><b>Código:</b></small> {{ codi }}
          </div>
        </div>
      </div>

      <div class="cs-footer" v-if="!saved">
        <button @click="previous" v-if="step !== 1">Anterior</button>
        <div v-else></div>
        <button @click="next" v-if="step !== 3">Siguiente</button>
        <button @click="submit" v-else>Enviar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Calendar from "@/components/Calendar.vue";
import TimeCards from "@/components/TimeCards.vue";

export default {
  name: "ClientCalendar",
  components: {
    Calendar,
    TimeCards,
  },
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  data() {
    return {
      step: 1,
      saved: false,
      codi: null,
      form: {
        etapa_id: "",
        name: "",
        email: "",
        phone: "",
        date: null,
        time: null,
      },
      loadingTimes: false,
      etapaItems: [],
      timeItems: [],
    };
  },
  computed: {
    client() {
      return this.$store.state.client;
    },
    calendar() {
      return this.$store.state.calendar;
    },
    pageTitle() {
      let title = undefined;

      if (this.client) {
        title = this.client?.name;
      }

      if (this.calendar) {
        title = this.calendar?.name + " - " + this.client?.name;
      }

      return title;
    },
    formTime() {
      return this.form.time?.[0] || null;
    },
  },
  watch: {
    "form.etapa_id"() {
      this.form.date = null;
      this.form.time = null;
      this.timeItems = [];
    },
    "form.date"(val) {
      this.form.time = null;
      this.timeItems = [];

      if (val !== null) {
        this.fetchTimes();
      }
    },
    calendar: {
      immediate: true,
      handler: function (value) {
        if (value !== null) {
          this.fetchEtapes();
        }
      },
    },
  },
  methods: {
    previous() {
      if (this.step > 1) this.step--;
    },

    next() {
      if (this.step === 1) {
        if (this.etapaItems.length > 0 && this.form.etapa_id == "") {
          return;
        }

        if (
          this.form.name.length > 0 &&
          this.form.email.length > 0 &&
          this.form.email.match(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/) &&
          this.form.phone.length > 0
        ) {
          this.step++;
        }
        return;
      }

      if (this.step === 2) {
        if (this.form.date !== null && this.form.time !== null) {
          this.step++;
        }
        return;
      }
    },

    async fetchEtapes() {
      const response = await axios.get(`/calendars/${this.calendar.id}/labels`);

      if (response.status === 200) {
        this.etapaItems = response.data;
      } else {
        alert("No se pudo cargar las etapas.");
      }
    },

    async fetchTimes() {
      this.loadingTimes = true;

      const response = await axios.get(
        `/calendars/${this.calendar.id}/appointments`,
        {
          params: {
            date: this.$options.filters.formatDate(this.form.date),
            free: 1,
          },
        }
      );

      if (response.status === 200) {
        this.timeItems = response.data;
      } else {
        alert("No se pudo cargar las horas.");
      }

      this.loadingTimes = false;
    },

    async submit() {
      await axios
        .post(`/calendars/${this.calendar.id}/appointments`, {
          label_id: this.form.etapa_id || undefined,
          date: this.$options.filters.formatDate(this.form.date),
          time_start: this.form.time[0],
          time_end: this.form.time[1],
          name: this.form.name,
          email: this.form.email,
          phone: this.form.phone,
        })
        .then((response) => {
          if (response.status === 201) {
            this.codi = response.data?.reference;
            alert("Su cita se ha reservado correctamente.");
            this.saved = true;
          }
        })
        .catch(() => {
          alert(
            "Su cita NO ha sido reservada. Revisa sus datos, el día y la hora."
          );
        });
    },
  },
  filters: {
    formatDate(date) {
      if (date instanceof Date) {
        const year = date.toLocaleDateString("en", { year: "numeric" });
        const month = date.toLocaleDateString("en", { month: "2-digit" });
        const day = date.toLocaleDateString("en", { day: "2-digit" });

        return `${year}-${month}-${day}`;
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
$wizard-padding: 35px;
$items-border-radius: 9px;

.cs-page {
  min-height: 100vh;
  background-color: #f9fafd;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cs-container {
  width: 100%;
  max-width: 900px;

  // height: 500px;
  // outline: 1px solid red;

  background-color: #fefeff;
  border-radius: $items-border-radius;
  box-shadow: 1px 1px 2px 1px #ccc;

  color: #6c757d;

  display: flex;
  flex-direction: column;
}

.cs-header .cs-top {
  height: 80px;

  padding: 0 $wizard-padding;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & .cs-title {
    font-size: inherit;
    font-weight: bold;
  }
}

.cs-header .cs-bottom {
  display: flex;

  & .cs-step {
    flex: 1 1 0px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    font-weight: 600;

    user-select: none;

    transition: color, background-color 0.3s;
    background-color: #eef2f7;

    &.active {
      color: #fff;
      background-color: #536de6;
    }
  }
}

.cs-body {
  flex: 1 1 0px;
  padding: $wizard-padding;

  display: flex;

  & .cs-step {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  & .cs-step-2 {
    gap: 25px;
    margin: 0 auto;
    max-width: 90%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    .cs-calendar {
      flex: 0 0 auto;
    }

    & .cs-timecards,
    & .cs-message-container {
      flex: 1 1 0px;
      min-width: 320px;
      max-height: 400px;
    }

    & .cs-message-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.cs-body .cs-step .cs-input-group {
  width: 100%;
}

.cs-body .cs-step .cs-input-group {
  & input {
    width: 100%;
    max-width: 300px;
    height: 40px;
    padding: 0 15px;
    border-radius: $items-border-radius;

    outline: none;
    color: #2b303d;
    border: 2px solid #b3bdd9;
    box-shadow: 0 1px 2px #dbdde2;

    transition: box-shadow 0.2s;

    &:focus {
      border-color: #1f5eff;
      box-shadow: 0 0 0 3px #b5c9fc;
    }
  }
}

.cs-footer {
  padding: $wizard-padding;
  padding-top: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    width: 200px;
    height: 40px;

    outline: none;
    border: none;
    border-radius: $items-border-radius;
    color: #fff;
    background-color: #1f5eff;
    border: 2px solid #1f5eff;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    user-select: none;

    &:focus {
      box-shadow: 0 0 0 3px #b5c9fc;
    }
  }
}
</style>
