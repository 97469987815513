<template>
  <div class="cs-page">
    <div class="cs-container">
      <div class="cs-header">
        <div class="cs-top">
          <h1 class="cs-title" v-if="cita">
            {{ cita.calendar.client.name }} - {{ cita.calendar.name }}
          </h1>
          <h1 class="cs-title" v-else>Cita</h1>
        </div>
      </div>

      <div class="cs-body" v-if="found === true">
        <div class="cs-input-group" v-if="cita.label">
          <div>Etiqueta</div>
          <div>
            <b>{{ cita.label.name }}</b>
          </div>
        </div>
        <div class="cs-input-group">
          <div>Día</div>
          <div>
            <b>{{ cita.date | substr(10) }}</b>
          </div>
        </div>
        <div class="cs-input-group">
          <div>Hora</div>
          <div>
            <b>{{ cita.time_start | substr(5) }}</b>
          </div>
        </div>
        <br />
        <div class="cs-input-group">
          <div>Nombre</div>
          <div>
            <b>{{ cita.name }}</b>
          </div>
        </div>
        <div class="cs-input-group">
          <div>Email</div>
          <div>
            <b>{{ cita.email }}</b>
          </div>
        </div>
        <div class="cs-input-group">
          <div>Número de teléfono</div>
          <div>
            <b>{{ cita.phone }}</b>
          </div>
        </div>
      </div>

      <div class="cs-body" v-else-if="found === false">
        <div>Cita no encontrada.</div>
      </div>

      <div class="cs-body" v-else>
        <div>Buscando cita...</div>
      </div>

      <div class="cs-body" v-if="deleted">
        <div>Cita eliminada correctamente.</div>
      </div>

      <div class="cs-footer">
        <div></div>
        <button v-if="found" @click="anular" :disabled="processing">
          Anular cita
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CitaInfo",
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  data() {
    return {
      found: null,
      cita: null,
      processing: false,
      deleted: false,
    };
  },
  computed: {
    pageTitle() {
      let title = undefined;

      if (this.client) {
        title = this.client?.name;
      }

      if (this.calendar) {
        title = this.calendar?.name + " - " + this.client?.name;
      }

      return title;
    },
  },
  async mounted() {
    this.fetchAppointment();
  },
  methods: {
    async fetchAppointment() {
      const response = await axios.get(
        `/appointments/${this.$route.params.appointment_reference}`,
        {
          validateStatus: () => true,
        }
      );

      if (response.status === 200) {
        this.cita = response.data;
        this.found = true;
      } else {
        this.found = false;
      }
    },

    async anular() {
      const confirmar = confirm(
        "¿Estás seguro de que deseas eliminar esta cita?"
      );

      if (confirmar) {
        this.processing = true;

        const response = await axios.delete(
          `/appointments/${this.$route.params.appointment_reference}`,
          {
            validateStatus: () => true,
          }
        );

        if (response.status === 204) {
          this.deleted = true;
          alert("Cita eliminada correctamente.");
        } else {
          alert("Error inesperado. No se ha podido eliminar la cita.");
        }

        this.processing = false;
      }
    },
  },
  filters: {
    substr(val, len) {
      return val.substr(0, len);
    },
  },
};
</script>

<style lang="scss" scoped>
$wizard-padding: 35px;
$items-border-radius: 9px;

.cs-page {
  min-height: 100vh;
  background-color: #f9fafd;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cs-container {
  width: 100%;
  max-width: 900px;

  // height: 500px;
  // outline: 1px solid red;

  background-color: #fefeff;
  border-radius: $items-border-radius;
  box-shadow: 1px 1px 2px 1px #ccc;

  color: #6c757d;

  display: flex;
  flex-direction: column;
}

.cs-header .cs-top {
  height: 80px;

  padding: 0 $wizard-padding;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & .cs-title {
    font-size: inherit;
    font-weight: bold;
  }
}

.cs-header .cs-bottom {
  display: flex;

  & .cs-step {
    flex: 1 1 0px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    font-weight: 600;

    user-select: none;

    transition: color, background-color 0.3s;
    background-color: #eef2f7;

    &.active {
      color: #fff;
      background-color: #536de6;
    }
  }
}

.cs-body {
  flex: 1 1 0px;
  padding: $wizard-padding;
  padding-top: 0;

  & .cs-step {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  & .cs-step-2 {
    gap: 25px;
    margin: 0 auto;
    max-width: 90%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    .cs-calendar {
      flex: 0 0 auto;
    }

    & .cs-timecards,
    & .cs-message-container {
      flex: 1 1 0px;
      min-width: 320px;
      max-height: 400px;
    }

    & .cs-message-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.cs-input-group {
  margin-bottom: 5px;
}

.cs-footer {
  padding: $wizard-padding;
  padding-top: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    width: 200px;
    height: 40px;

    outline: none;
    border: none;
    border-radius: $items-border-radius;
    color: #fff;
    background-color: #1f5eff;
    border: 2px solid #1f5eff;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    user-select: none;

    &:focus {
      box-shadow: 0 0 0 3px #b5c9fc;
    }
  }
}
</style>
